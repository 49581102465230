<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-left justify-space-around fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij iskanja</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('card1')">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">

                                <!-- tipi omejitev -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip omejitve</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_restriction_types"
                                            :items="restrictionTypes"
                                            label="Izberi tip omejitve"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                
                                <!-- ID kartice -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ID kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="card_id"
                                            label="Vpiši ID kartice"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearCardId"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- telefonska številka -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Telefonska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="phone_number"
                                            label="Vpiši telefonsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearPhoneNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- Registrska številka -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Registrska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="registration_number"
                                            label="Vpiši registrsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearRegistrationNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                            </v-card-text>
                            <v-divider v-if="card1">
                            </v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block outlined style=""><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                                </v-card-text>
                            </span>
                    </v-card>
                </v-flex>
            </v-layout>
            <br>
            <users-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></users-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
const UsersDataTable = () => import('@/components/UsersDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {
    components: {
        UsersDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        card1: true,
        card2: true,
        form: true,
        phone_number: null,
        card_id: null,
        registration_number: null,
        selected_restriction_types: [],

        restrictionTypes: [],

        dataTableSettings: {
            id: 'dtEvents',
            title: 'Seznam uporabnikov',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                { text: "Uporabnik", value: "name", class: "text-xs-left", visibility: true},
                { text: "Tip omejitve", value: "restriction", class: "text-xs-left", visibility: true },
                { text: "ID kartice", value: "card_id", class: "text-xs-left", visibility: true },
                { text: "Številka kartice", value: "card_number", class: "text-xs-left", visibility: true },
                { text: "Telefonske številke", value: "phone_numbers", class: "text-xs-left", visibility: true, sortable: false },
                { text: "Registrske številke", value: "registration_numbers", class: "text-xs-left", visibility: true, sortable: false },
                { text: "Veljavnost od", value: "restriction_start_date", class: "text-xs-left", visibility: true, sortable:true },
                { text: "Veljavnost do", value: "restriction_end_date", class: "text-xs-left", visibility: true, sortable:true },
                { text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'mangart/rfid-users',
            editItem: {
                route: 'edit_user',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_RFID_USER',
                replaceStrWithColumnValue: 'name',
                confirmationText : "Sta prepričani da želite odstraniti uporabnika {str_to_replace} ?",
                dialogConfirmSuccessText: 'Uporabnik {str_to_replace} je bil uspešno odstranjen.',
                dialogConfirmFailText: 'Pri odstranjevanju uporabnika {str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: true,
                    addNewRoute: 'add_user',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_rfid_users',
                totalItems : {
                    event: ''
                },
                search: 'dt-rfid-users-search',
                toggleColumnsId: 'toggle-rfid-users-list-columns'
            }


        }
    }),

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                cardId: this.card_id,
                phoneNumber: this.phone_number,
                selectedRestrictionTypes: this.selected_restriction_types.map(item => item.id),
                registrationNumber: this.registration_number
            }
        }
    },

    watch: {

    },

    methods: {
        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }
        },

        search() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 250)
        },

        clearPhoneNumber() {
            this.phone_number = null
        },

        clearCardId() {
            this.card_id = null
        },

        clearRegistrationNumber() {
            this.registration_number = null
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
    },

    mounted() {
        var vm = this
        vm.$store.commit('SET_PROGRESS', true)
        vm.$store.dispatch('PARAMETERS_DATA')
        .then(response => {
            //window.console.log(response.data)

            response.data.restrictionTypes.forEach(restrictionType => {
                vm.restrictionTypes.push(restrictionType)
            })
        })
        .catch(error => {
            window.console.error("### ERROR Users.vue@mounted ###");
            window.console.error(error)
        })
        .finally(() => {
            vm.$store.commit('SET_PROGRESS', false)
        })
    },

    destroyed() {
        EventBus.$off(this.dataTableSettings.id)
    }
}

</script>

<style scoped>

</style>